.progress-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  height: 225px;
  box-sizing: border-box;
  background-color: #00184e !important;
  box-sizing: border-box;
  color: white;
}

.progress-container-row1 {
  margin-bottom: 35px;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  text-transform: capitalize;
  text-align: center;
}

.progress-container-row2 {
  width: 300px;
}

.progress-container-row3 {
  font-size: 14px;
  padding: 4px;
  padding: 10px;
}

@media (max-width: 575px) {
  .progress-container-row1 {
    font-size: 23.3333px;
    line-height: 34.2857px;
  }
}
