.zipcode-form-section-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 25px;
  padding-bottom: 25px;
  background-position: left !important;
  background-size: cover !important;
  min-height: 500px;
  flex-direction: column;
}

.zipcode-form-section-title {
  font-weight: 600;
  color: #ffffff;
  font-size: 42px;
  line-height: 40px;
  text-align: center;
  margin-top: 35px;
}

.zipcode-form {
  width: 100%;
  max-width: 380px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff !important;
  border-radius: 5px !important;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 60px;
  margin-bottom: 60px;
}

.zipcode-form-label {
  color: #3f4b5b;
  padding-bottom: 24px;
  font-size: 23px;
  font-weight: 700;
  line-height: 1.2;
}

.zipcode-form-input {
  width: 100%;
  padding: 10px;
  color: #555555 !important;
  border: 1px solid #bfc3c8 !important;
  box-shadow: 0 1px 1px rgb(0 0 0 / 8%) inset !important;
  border-radius: 3px !important;
  font-size: 19px;
  text-align: center;
  background-color: #fff;
  height: 42px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.zipcode-form-input:focus {
  border-color: #09bc8a !important;
  box-shadow: 0 1px 1px rgb(0 0 0 / 8%) inset, 0 0 8px rgb(9 188 138 / 60%) !important;
}

.zipcode-form-button {
  box-shadow: none !important;
  border: 0 !important;
  font-size: 18px;
  border-radius: 4px !important;
  background: #09bc8a !important;
  height: 56px;
  font-weight: 600;
  margin-bottom: 20px;
  width: 100%;
  cursor: pointer;
  color: #fff;
  transition: color 0.15s, background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  padding: 8px 16px;
  line-height: 1.42857;
  display: flex;
  align-items: center;
  justify-content: center;
}

.zipcode-error {
  font-size: 13px;
  color: #de2929;
}

.zipcode-form-button:hover {
  background-color: #00184e !important;
}

.zipcode-form-contact-us-section {
  width: 100%;
  text-align: center;
  line-height: 1.75;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.05em;
  color: #222529;
  box-sizing: border-box;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.zipcode-form-contact-us-section a {
  color: #09bc8a;
}

@media only screen and (max-width: 750px) {
  .zipcode-form-label {
    font-size: 17px;
  }
  .zipcode-form-section-title {
    font-size: 32px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .zipcode-form-contact-us-section {
    font-size: 10.8889px;
  }
}
