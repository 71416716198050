.primary-label-container {
  display: flex;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  padding-top: 30px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.primary-label {
  font-size: 36px;
  line-height: 46px;
  margin-bottom: 15px;
  font-weight: 600;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  max-width: 760px;
  color: #3f4b5b;
}

@media only screen and (max-width: 991px) {
  .primary-label-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/*  width less than 750px*/
@media only screen and (max-width: 749px) {
  .primary-label {
    font-size: 24px;
    line-height: 34px;
  }
}
